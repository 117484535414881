import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout title="Privacy Policy">{children}</Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PRIVACY POLICY`}</h1>
    <p>{`This `}<strong parentName="p">{`Privacy Policy`}</strong>{` describes the information FaintLines, Inc. ("`}<strong parentName="p">{`FaintLines`}</strong>{`","`}<strong parentName="p">{`we`}</strong>{`", "`}<strong parentName="p">{`our`}</strong>{`", or "`}<strong parentName="p">{`us`}</strong>{`") collects, how we use this information, with whom we share it, and the choices you have in connection with this. This Privacy Policy applies to the FaintLines mobile application (the "`}<strong parentName="p">{`Application`}</strong>{`"), the FaintLines website, and any other electronic or digital products made available by FaintLines that link to this Policy (our "`}<strong parentName="p">{`Services`}</strong>{`").`}</p>
    <h2>{`INFORMATION COLLECTION AND USE`}</h2>
    <p>{`Through your use of the Services, we will collect personal information, which is information that identifies you or relates to you as an identifiable individual. We collect personal information directly and automatically as outlined below.`}</p>
    <h3>{`A. Information Collected From You`}</h3>
    <p>{`When you engage with certain features of the Services, we will collect your personal information. Except as otherwise indicated, the personal information we collect is such that we need to carry out the requested action. If you do not provide us with your personal information, we would not be able to do so.We collect personal information when you:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Become a creator of content for us:`}</ins></strong>{` If you would like to contribute to a game, we will collect from you, your `}<em parentName="p">{`personal identifiers`}</em>{` (name and email address) and any other information you choose to include in your correspondence, such as your ideas or proposed adventure. We use this personal information to communicate and collaborate with you. To the extent EU data protection law applies, the legal basis for this is performance of our contract with you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Contact us or complete a form on our Website:`}</ins></strong>{` When you contact us, or complete a form on our Services, we collect, from you, your `}<em parentName="p">{`personal identifiers`}</em>{` (name and email address) and any other information you choose to include in your correspondence. We use this personal information to respond to your questions or inquiries, troubleshoot where necessary, and address any issues you may have. To the extent EU data protection law applies, the legal basis for is performance of our contract with you. Our communications contain tracking technologies, provided by SendGrid, to gain insight into behavior of recipients. With the help of these technologies, we can analyze whether a predefined action took place by a recipient, such as opening our communications and clicking on links in them, in order to better adapt and distribute our communications. When you contact us, you are opting in to the use of these technologies. You can disable tracking by disabling the display of images by default in your email program.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Create a profile:`}</ins></strong>{` When you create a profile in our Services, we will collect, from you, your `}<em parentName="p">{`personal identifiers`}</em>{` (name, email address, telephone number, city, state, country, IP address, and selected username and password). We use this personal information to create your profile, communicate with you about the Services, and facilitate your access to the Services. To the extent EU data protection law applies, the legal basis for this is the performance of our contract with you. If you agree, we will also use your personal identifiers to send you marketing communications we feel may be of interest to you, such as future games and adventures. When you consent to text message and telephone marketing, message and data rates may apply. To the extent EU data protection law applies, the legal basis for this is your consent.`}</p>
        <p parentName="li">{`Once your profile is created, we will assign you a unique identifier to track and store your activities in the Services associated with a particular adventure, such as your mobile device. We use this personal information to track your progress in the game, to move you along in the adventure and for other purposes as described further in `}<strong parentName="p">{`Information Collected Automatically`}</strong>{`. To the extent EU data protection law applies, the legal basis for this is the performance of our contract with you. We use also use this information in the aggregate for analytics purposes, and for improving the adventures.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Engage with an adventure:`}</ins></strong>{` Our adventures are personalized to you to provide you with an immersive and personal experience.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`We will `}<em parentName="li">{`send you emails`}</em>{` as part of the adventure. We track your progress via your interaction with these emails (see above). As we track your progress, we will create `}<em parentName="li">{`inferences`}</em>{` from the actions you have selected to provide you with more personalized content and recommendations. (For example: if you interact more with female characters, we may present you with more female characters).`}</li>
          <li parentName="ul">{`When you engage with a `}<em parentName="li">{`chat bot`}</em>{` as part of the experience we collect, from you, all of the information provided in the chat. We review this information and use it to improve our products. Please do not disclose any sensitive information (e.g. real passwords) when engaging with chat bots.`}</li>
          <li parentName="ul">{`When you engage with a `}<em parentName="li">{`live actor`}</em>{`, we will transcribe your conversations to analyze the context so we can make determinations regarding the continuation of your adventure, assess the performance of the actors and improve our product. The live actors will not ask you for personal information and you should not disclose any personal information.`}</li>
          <li parentName="ul">{`You have the option of providing your `}<em parentName="li">{`phone number`}</em>{` in order to receive phone calls and text messages from the adventure to make the adventure more realistic. Certain adventures are not possible without the activation of the text message capability. In others, you may select the option of other methods of communication.`}</li>
          <li parentName="ul">{`We will also use the `}<em parentName="li">{`personal identifiers`}</em>{` you provide in your Services profile to tailor an adventure to you. For example, we will use your device location or IP address to infer your location to tailor character names and locations. To the extent EU data protection law applies, the legal basis for this is your consent.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Make a purchase:`}</ins></strong>{` If you purchase the Services, we will, collect from you, your `}<em parentName="p">{`payment information`}</em>{` (payment card number, expiration date, and CVV) to process your purchase. As you progress through an adventure, you will also be presented with the option to purchase premium content within the Services. Please note our payment processor, Stripe, collects and processes your payment information on our behalf and FaintLines only maintains historical payment information. To the extent EU data protection law applies, the legal basis for this is performance of our contract with you. If you decide to pay through Apple Pay or Google Pay, Apple Pay or Google Pay will collect your payment information directly and this personal information is governed by their respective privacy policies. FaintLines will receive a transaction-specific code or anonymized token from these providers to confirm your payment`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Subscribe to marketing emails:`}</ins></strong>{` If you subscribe to our marketing emails, we will collect your `}<em parentName="p">{`email address`}</em>{` to fulfill your request to receive information we feel may be of interest to you. The legal basis for this is your consent. You can unsubscribe at any time by clicked on the "unsubscribe" link in each email. Our communications contain tracking technologies, provided by SendGrid, to gain valuable insight into behavior of recipients. With the help of these technologies, we can analyze whether a predefined action took place by a recipient, such as opening our communications, in order to better adapt and distribute our communications. When you opt-in to our marketing communications, you are opting in to the use of these technologies. You can disable tracking by disabling the display of images by default in your email program.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Visit certain locations:`}</ins></strong>{` Some adventures include location-based games that are tied to a real world location. For these adventures, we need to know where you are so you are able to participate in the adventure. If you grant us permission, through the permissions on your device, we will automatically capture your `}<em parentName="p">{`geolocation`}</em>{` through your mobile device. The legal basis for this is your consent. You may revoke your consent at any time. Please note that certain location based games will not be available if you revoke your consent to the location tracking.`}</p>
      </li>
    </ul>
    <p>{`We will review and analyze your voice recordings, videos, text transcriptions of the same or of your conversations with the chat bots, in an aggregated manner, to conduct analytics and benchmarks to improve our product. We also use this data to train AI models for improving our chatbots.`}</p>
    <p>{`FaintLines may also use the personal information collected for the above purposes to comply with the law and for other limited circumstances as described in `}<strong parentName="p">{`INFORMATION SHARING.`}</strong></p>
    <h3>{`B. Information Collected From Third Party Sources`}</h3>
    <p>{`If an adventure involves interaction with third party social media websites, such as Facebook, we will receive information from these social media websites. This information includes the `}<em parentName="p">{`identifiers`}</em>{` (information on your profile), transcripts of your `}<em parentName="p">{`conversations`}</em>{` with the chatbots on these social media websites, and your followers.`}</p>
    <h3>{`C. Information Collected Automatically`}</h3>
    <p>{`In addition to the personal information you provide, we also collect information automatically via cookies as you use the Services. This information includes the following `}<em parentName="p">{`internet and other electronic network activity information`}</em>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Usage information:`}</ins></strong>{` We use essential, performance, and analytics cookies to collect information about your interaction with our Services and adventures, such as what you access, what you click on, the frequency of access, and how much time you spend on the Services. We use this information to: (i) track you within the Services to progress the adventure; (ii) enhance user experience; (iii) conduct analytics to improve the Services; (iv) prevent fraudulent use of the Services; and (v) diagnosis and repair Services errors and in cases of abuse, track and mitigate the abuse.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Device information:`}</ins></strong>{` We use essential, performance, and analytics cookies to collect certain information about the device you use to access the Services, such as hardware model, operating system, and device preferences. We use this information to: (i) track you within the Services to progress the adventure; (ii) enhance user experience; (iii) conduct analytics to improve the Services; (iv) prevent fraudulent use of the Services; and (v) diagnosis and repair Services errors and in cases of abuse, track and mitigate the abuse.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Location information:`}</ins></strong>{` We use essential, performance, and analytics cookies to collect information about your location, which may be determined through your IP address. We use this information to: (i) track you within the Services to progress the adventure; (ii) enhance user experience; (iii) conduct analytics to improve the Services; (iv) prevent fraudulent use of the Services; and (v) diagnosis and repair Services errors and in cases of abuse, track and mitigate the abuse.`}</p>
      </li>
    </ul>
    <p>{`In general, to disable cookies and limit the collection and use of information through them, you can set your browser to refuse cookies or indicate when a cookie is being sent. To opt-out of interest based advertising generally or to learn more about the use of this information by our service providers you can visit the `}<a parentName="p" {...{
        "href": "http://www.networkadvertising.org/managing/opt_out.asp"
      }}>{`Network Advertising Initiative`}</a>{` or the `}<a parentName="p" {...{
        "href": "http://www.aboutads.info/choices/"
      }}>{`Digital Advertising Alliance`}</a>{`.`}</p>
    <p>{`Particular cookies to note on the Services include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Google Analytics:`}</ins></strong>{` We use Google Analytics to conduct analytics. In doing so, Google Analytics may set cookies on your browser, or read cookies that are already there. Google Analytics may also receive information about you from applications you have downloaded that partner with Google. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Google will use and share the information collected through Google Analytics in accordance with its `}<a parentName="p" {...{
            "href": "http://www.google.com/policies/privacy/partners/"
          }}>{`Terms of Use and Privacy Policy.`}</a>{` To opt-out, you can download the Google Analytics `}<a parentName="p" {...{
            "href": "https://tools.google.com/dlpage/gaoptout"
          }}>{`opt-out browser add-on`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Google Ads:`}</ins></strong>{` We use Google Ads to deliver advertisements to you and to track whether you have interacted with an advertisement we have placed elsewhere on the internet. Google Ads stores a conversion tracking cookie on your device when you click on our advertisement. The information obtained through the cookie is used to generate statistics and allows us to see the total number of users who clicked on our advertisements. We also use Google Ads to present to users of our Services advertisements across the internet and within the Google advertising network based on their visits to our Website. For more information, please review Google's `}<a parentName="p" {...{
            "href": "https://policies.google.com/technologies/ads?hl=en-US"
          }}>{`privacy policy`}</a>{`. To opt-out of targeted advertising by Google, you can go to Google's `}<a parentName="p" {...{
            "href": "https://www.google.com/settings/ads"
          }}>{`ad settings`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Hotjar:`}</ins></strong>{` We use Hotjar in order to better understand user experience and to optimize the Services. Hotjar allows us to better understand user experience (`}<em parentName="p">{`e.g`}</em>{`. how much time you spend on which pages, which links you choose to click, what users do and don't like) and this enables us to build and maintain our Services. For further details, please see the 'about Hotjar' section of `}<a parentName="p" {...{
            "href": "https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
          }}>{`Hotjar's support services`}</a>{`. You can opt-out of HotJar `}<a parentName="p" {...{
            "href": "https://www.hotjar.com/legal/compliance/opt-out/"
          }}>{`here`}</a>{`.`}</p>
      </li>
    </ul>
    <h2>{`INFORMATION SHARING`}</h2>
    <p>{`FaintLines may share the personal information identified in this Privacy Policy in the following instances:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`With service providers:`}</ins></strong>{` We share your personal information with our service providers that assist us in providing the Services, such as our IT support, payment processor, marketing provider, communications provider, cloud and hosting provider, and email provider. To the extent EU data protection law applies, the legal basis for this is our legitimate interest in providing the Services more efficiently.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`With other users of the Services:`}</ins></strong>{` When you participate in multi-player adventures on the Services, we will share your personal information with the other players to connect players and facilitate communication. This includes your nickname, avatar, country, and level in the Services. The legal basis for this is performance of our contract with you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Within FaintLines:`}</ins></strong>{` Where necessary, we share your personal information within FaintLines for legitimate business purposes in order to efficiently carry out our business and to the extent permitted by law. The legal basis for this is our legitimate interest in carrying out our business operations efficiently.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`In the event of a corporate reorganization:`}</ins></strong>{` In the event that we enter into, or intend to enter into, a transaction that alters the structure of our organization, such as a reorganization, merger, acquisition, sale, joint venture, assignment, consolidation, transfer, change of control, or other disposition of all or any portion of our assets, we would share your personal information with third parties, including the buyer or target (and their agents and advisors) for the purpose of facilitating and completing the transaction. We would also share your personal information with third parties if we undergo bankruptcy or liquidation, in the course of such proceedings. The legal basis for this is our legitimate interest in carrying out our business operations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`For legal purposes:`}</ins></strong>{` We will share your personal information where we are legally required to do so, such as in response to court orders, law enforcement or legal process, including for national security purposes; to establish, protect, or exercise our legal rights, as required to enforce our terms of use or other contracts; to defend against legal claims or demands; to detect, investigate, prevent, or take action against illegal activities, fraud, or situations involving potential threats to the rights, property, or personal safety of any person; or to comply with the requirements of any applicable law. The legal basis for this is compliance the law, compliance with legal obligations, and our legitimate interest in the protection of the rights of others.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`With your consent:`}</ins></strong>{` Apart from the reasons identified above, we may request your permission to share your personal information for a specific purpose. We will notify you and request consent before you provide the personal information or before the personal information you have already provided is shared for such purpose. You may revoke your consent at any time.`}</p>
      </li>
    </ul>
    <h2>{`RIGHTS OF INDIVIDUALS IN THE EUROPEAN UNION`}</h2>
    <p>{`Individuals in the European Union are entitled to certain rights under the General Data Protection Regulation (GDPR). If our processing of your personal information is subject to the GDPR, you may be entitled to the following rights:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to access`}</ins>{`:`}</strong>{` You have the right to ask us for copies of your personal information. This right has some exemptions, which means you may not always receive all the personal information we process.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to rectification`}</ins>{`:`}</strong>{` You have the right to ask us to rectify personal information you think is inaccurate or incomplete.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to erasure`}</ins>{`:`}</strong>{` You have the right to ask us to erase your personal information in certain circumstances.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to restrict processing`}</ins>{`:`}</strong>{` You have the right to ask us to restrict the processing of your personal information in certain circumstances.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to object to processing`}</ins>{`:`}</strong>{` You have the right to object at any time, for reasons arising from your particular situation, to processing of your personal information, which is carried out on the basis of our legitimate interests.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to data portability`}</ins>{`:`}</strong>{` You have the right to ask that we transfer the personal information you gave us from one organization to another, or give it to you. Please note this only applies to personal information you have given us.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Right to lodge a complaint`}</ins>{`:`}</strong>{` You have the right to lodge a complaint with the relevant Supervisory Authority.`}</p>
      </li>
    </ul>
    <p>{`To exercise these rights, please contact us at `}<a parentName="p" {...{
        "href": "mailto:support@faintlines.com"
      }}>{`support@faintlines.com`}</a>{`.`}</p>
    <h2>{`NEVADA RESIDENTS`}</h2>
    <p>{`If you are a consumer in the State of Nevada, you may request to opt-out of the current or future sale of your personal information. We do not currently sell any of your personal information under Nevada law, nor do we plan to do so in the future. However, you can submit a request to opt-out of future sales by contacting us at `}<a parentName="p" {...{
        "href": "mailto:support@faintlines.com"
      }}>{`support@faintlines.com`}</a>{`. Please include "Opt-Out Request Under Nevada Law" in the subject line of your message.`}</p>
    <h2>{`INFORMATION CHOICES`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Correct or view your personal information:`}</ins></strong>{` You may access your FaintLines profile to correct or view certain personal information of yours in our possession and which is associated with your profile.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Location settings:`}</ins></strong>{` You can prevent your mobile device from sharing your location data by adjusting the permissions on your mobile device or within the Services.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Marketing emails:`}</ins></strong>{` You may opt-out of receiving marketing emails from us by clicking the "unsubscribe" link provided with each email. Please note that we will continue to send you emails necessary to the Services or any assistance you request.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Marketing text messages:`}</ins></strong>{` You may opt-out of receiving marketing text messages from us by responding STOP to any of our text messages.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Push notifications:`}</ins></strong>{` If you have enabled push notifications, you may disable these at any time by updating your device settings.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><ins>{`Uninstall the Application:`}</ins></strong>{` You can stop all further collection of your personal information by the Services by uninstalling the Application.`}</p>
      </li>
    </ul>
    <h2>{`RETENTION OF PERSONAL INFORMATION`}</h2>
    <p>{`We will retain your personal information until the earlier of: (i) the personal information is no longer necessary to accomplish the purpose for which it was provided; or (ii) we delete your information pursuant to your request. We may retain your personal information for longer periods for specific purposes to the extent that we are obliged to do so in accordance with applicable laws and regulations and/or as necessary to protect our legal rights or for certain business requirements. This includes for security, fraud, and abuse prevention, for financial record-keeping, or to comply with legal or regulatory requirements.`}</p>
    <h2>{`DO NOT TRACK`}</h2>
    <p>{`We do not support Do Not Track (DNT). Do Not Track is a preference you can set to inform websites and applications that you do not want to be tracked.`}</p>
    <h2>{`INFORMATION SECURITY`}</h2>
    <p>{`We implement and maintain reasonable security measures, such as access controls and encryption, to protect the personal information we collect and maintain. However, no security measure or modality of data transmission over the Internet is 100% secure and we are unable to guarantee the absolute security of the information we have collected from you.`}</p>
    <h2>{`AGE RESTRICTION`}</h2>
    <p>{`The Services are not intended for individuals under the age of sixteen (16). If we learn that we have collected or received personal information from a child under the age of sixteen (16), we will delete that information. If you believe we might have information from or about a child under the age of sixteen (16), please contact us at `}<a parentName="p" {...{
        "href": "mailto:support@faintlines.com"
      }}>{`support@faintlines.com`}</a>{`.`}</p>
    <h2>{`CHANGES TO THIS PRIVACY POLICY`}</h2>
    <p>{`We may change this Privacy Policy from time to time. We will post the changes to this page and will indicate the date the changes go into effect. We encourage you to review our Privacy Policy to stay informed. If we make changes that materially affect your privacy rights, we will notify you with a prominent post on our Services and obtain your consent, if required.`}</p>
    <h2>{`CONTACT`}</h2>
    <p>{`If you have any questions about this Privacy Policy, please contact us at `}<a parentName="p" {...{
        "href": "mailto:support@faintlines.com"
      }}>{`support@faintlines.com`}</a>{`.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`This Privacy Policy was last updated on July 21, 2021`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      